import React, {useState} from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import {graphql} from "gatsby"
import {Row, Col} from "react-bootstrap";
import homepageStyle from "./index.module.css";
import Img from "gatsby-image";
import addToMailchimp from "gatsby-plugin-mailchimp";
import env from "../config/env";
import Carousel from "react-multi-carousel";
import TeamCarousel from "../components/team-carousel";
import globalStyle from "../styles/global.module.css";

import HomeCard from "../components/homeCard"

const Index = ({data}) => {
  const resourceImages = {};

  data.teamImages.edges.map(item => {
    resourceImages[item.node.name] = item.node;
  });

  const [showCoffeeChatNameErrorMessage, setShowCoffeeChatNameErrorMessage] = useState({ show: false, message: "" });
  const [showCoffeeChatEmailErrorMessage, setShowCoffeeChatEmailErrorMessage] = useState({ show: false, message: "" });
  const [showCoffeeChatDatesCallErrorMessage, setShowCoffeeChatDatesCallErrorMessage] = useState({ show: false, message: "" });
  const [formResult, setFormResult] = useState({});
  const [showAlert, setShowAlert] = useState({show: false, msg: ""});
  const [showError, setShowErrorAlert] = useState({show: false, onField: ""});
  const options = [
    { label: "May 9 2022", value: "May" },
    { label: "June 13 2022", value: "June" },
    { label: "July 11 2022", value: "July" },
    { label: "August 08 2022", value: "August" },
    { label: "September 12 2022", value: "September" },
    { label: "October 10 2022", value: "October" },
    { label: "November 14 2022", value: "November" },
    { label: "December 12 2022", value: "December" },
  ]
  const [selected, setSelected] = useState([]);

  const [coffeeChatFormState, setCoffeeChatFormValues] = useState({
    coffeeChatName: "",
    coffeeChatEmail: "",
    coffeeChatDates: "",
  });

  const handleCoffeeChatFormChange = e => {
    let value = e.target.value;

    console.log(coffeeChatFormState)
    setCoffeeChatFormValues({
      ...coffeeChatFormState,
      [e.target.name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (!coffeeChatFormState.coffeeChatName) {
      isValid = false;
      setShowCoffeeChatNameErrorMessage({
        show: true,
        message: "Please enter your name!"
      })
    }
    if (!coffeeChatFormState.coffeeChatEmail) {
      isValid = false;
      setShowCoffeeChatEmailErrorMessage({
        show: true,
        message: "Please enter your email!"
      })
    }

    return isValid
  };

  const saveToMailchimp = async () => {
    try {
      const result = await addToMailchimp(coffeeChatFormState.coffeeChatEmail, {
        NAME: coffeeChatFormState.coffeeChatName,
        EMAIL: coffeeChatFormState.coffeeChatEmail,
        DATES: coffeeChatFormState.coffeeChatDates,
        }, env.coachingCoffeeChat
      );

      const alreadyAMemberError = coffeeChatFormState.coffeeChatEmail + ` is already subscribed to list`;
      setFormResult(result);
      if (result.result === 'error' && result.msg.includes(alreadyAMemberError)) {
        setShowAlert({
          show: true,
          msg: 'You already subscribed to list!'
        });
      } else {
        setShowAlert({
          show: true,
          msg: result.msg
        });
      }

    } catch (err) {
      console.error(err);
    }
    return true;
  };

  const addSelectedDates = () => {

    if(selected) {
      let dates = []
      selected.map(function (item) {
        dates.push(item.label);
      });

      coffeeChatFormState.coffeeChatDates = dates.toString();
      let isValid = true;
      if (!coffeeChatFormState.coffeeChatDates) {
        isValid = false;
        setShowCoffeeChatDatesCallErrorMessage({
          show: true,
          message: "Please select one or more dates!"
        })
      }
    }
    return;
  }

  const validateAndSaveCoffeeChatFormData = async () => {
    if (validateForm()) {
      addSelectedDates();
      await saveToMailchimp();
    }
  }

  return (
    <Layout
      disableLayoutPadding={true}
      structuredData={{
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Moms on Maternity",
      "legalName": "Moms on Maternity, Inc.",
      "url": "https://www.momsonmaternity.com",
      "foundingDate": "2017",
      "founders": [
        {
          "@type": "Person",
          "name": "Aimee M Cruz"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/MomsOnMaternity/",
        "https://www.youtube.com/channel/UCCAGR4adI21ao1Jp7CTY32g",
        "https://www.instagram.com/momsonmaternity/"
      ],
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "4403 Muir Ave",
        "addressLocality": "San Diego",
        "addressRegion": "CA",
        "postalCode": "92107",
        "addressCountry": "USA"
      },
      "logo": {
        "@type": "ImageObject",
        "url": env.domain + data.logo.publicURL
      }
    }}>

      <Row>
        <Col>
          {/*<Row>*/}
          {/*  <Col className={homepageStyle.coverPhotoImage}>*/}
          {/*    <Img className={homepageStyle.coverPhoto} fluid={data.frontpageCover.childImageSharp.fluid}></Img>*/}
          {/*    <div className={homepageStyle.textBlock}>*/}
          {/*      <p className={homepageStyle.title}>Calling All Expecting and New Parents!</p>*/}
          {/*    </div>*/}
          {/*    <div className={homepageStyle.actionButtonContent}>*/}
          {/*      <a*/}
          {/*        type="button"*/}
          {/*        href="#zoomTitle"*/}
          {/*        className={[ homepageStyle.actionButton ]}*/}
          {/*      >*/}
          {/*        Get Your Invite*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          <Row>
            <Col>
              <h3 className={[globalStyle.entryTitle, homepageStyle.entryTitleHomepage].join(" ")}>
                Our passion is connecting parents!</h3>
              <h3 className={[globalStyle.entryTitle, homepageStyle.entryTitleHomepage, homepageStyle.entrySubTitleHomepage].join(" ")}>
                Welcome to Moms on Maternity, home of Mommy's Helper and Tummy Time Club
              </h3>
            </Col>
          </Row>

          <Row className={homepageStyle.aboutSection}>
            <Col md={5} sm={5} xs={12} className={"offset-md-1"}>
              <Img fluid={data.aboutUsPhoto.childImageSharp.fluid}/>
            </Col>
            <Col md={5} sm={5} xs={12} className={"offset-md-0"}>
              <Row className={[homepageStyle.secondTitle]}>
                <Col>
                  <h4 className={homepageStyle.blackZoomTitle}>About Moms on Maternity</h4>
                  <p className={homepageStyle.aboutStyle}>
                    Moms on Maternity was started in 2017 when Aimee Cruz was on Maternity Leave with her second son.
                    Aimee wanted to meet more women planning to go back to work while raising a family. She believes we
                    100% can be Great Moms and Great Professionals.
                    Moms on Maternity now provides parenting content and inspiration that helps as our babies grow up–
                    and as we ourselves continue to journey as women.
                  </p>
                  <p className={homepageStyle.aboutStyle}>
                    Please take time to look over our <a className={globalStyle.linkTo} href="https://www.tummytimeclub.com/" target="_blank" rel="noopener noreferrer">
                    Tummy Time Club</a> perfect for the new and expecting Mom looking for community.
                  </p>
                  <p className={homepageStyle.aboutStyle}>
                    Check out our <Link className={globalStyle.linkTo} to="/mommys-helper/">Mommy's Helper Program</Link>, perfect for the family looking for support at home
                    from a local college student.
                  </p>
                  <p className={homepageStyle.aboutStyle}>Thank you and we wish you the very best!</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <h1 className={[homepageStyle.blackZoomTitle].join(" ")}>
                Why Community is Important by Moms on Maternity
              </h1>
            </Col>
          </Row>

          <Row>
            <Col md={5} sm={8} xs={12} className={"offset-md-1"} className={homepageStyle.aboutCommunitySection}>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item"
                        src="https://www.youtube.com/embed/tbo9OW-sYn4"
                        allowFullScreen>
                </iframe>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-md-center">

            <HomeCard
                 href={'/labor-book'}
                 styles={homepageStyle}
                 fluid={data.laborBookPhoto.childImageSharp.fluid}
                 buttonText={"The Book"} />

            <HomeCard
                 href={"/mommys-helper"}
                 styles={homepageStyle}
                 fluid={data.mommyshelperPhoto.childImageSharp.fluid}
                 buttonText={"Mommy's Helper"} />

            <HomeCard
                 href={"https://www.tummytimeclub.com"}
                 styles={homepageStyle}
                 fluid={data.tummytimePhoto.childImageSharp.fluid}
                 buttonText={"The Club"} />

          </Row>

          <Row className={[homepageStyle.secondLine, "justify-content-md-center"].join(' ')}>
            <HomeCard
                 href={"https://podcasts.apple.com/us/podcast/the-moms-on-maternity-podcast-with-aimee-cruz/id1556912487"}
                 styles={homepageStyle}
                 fluid={data.podcastPhoto.childImageSharp.fluid}
                 buttonText={"Podcasts"} />

            {data.vlogs.edges.map(({node}) => (
                <HomeCard
                     href={node.frontmatter.path}
                     styles={homepageStyle}
                     fluid={node.frontmatter.squaredImage.childImageSharp.fluid}
                     buttonText={"Vlogs"} />
            ))}

            {data.allMarkdownRemark.edges.map(({node}) => (
                <HomeCard
                     href={node.frontmatter.path}
                     styles={homepageStyle}
                     fluid={node.frontmatter.squaredImage.childImageSharp.fluid}
                     buttonText={"Blogs"} />
            ))}

          </Row>

          {/*<Row className={homepageStyle.zoomTitle}>*/}
          {/*  <Col>*/}
          {/*    <h4>Upcoming Event</h4>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          {/*<Row>*/}
          {/*  <Col md={8} sm={12} xs={12} className={"offset-md-2"}>*/}
          {/*    <a href="https://www.eventbrite.com/e/mindfulness-yoga-and-creativity-for-families-tickets-164783043519"*/}
          {/*       target="_blank">*/}
          {/*      <Img fluid={data.mindfulnessUpcomingEventPhoto.childImageSharp.fluid}/>*/}
          {/*    </a>*/}

          {/*  </Col>*/}
          {/*</Row>*/}

          {/*<Row className={"justify-content-md-center"}>*/}
          {/*  <Col md={8} sm={12} xs={12} className={homepageStyle.eventButtonContent}>*/}
          {/*    <Link id="hoverShowImage"*/}
          {/*          to="https://www.eventbrite.com/e/mindfulness-yoga-and-creativity-for-families-tickets-164783043519"*/}
          {/*          target="_blank">*/}
          {/*      <Button type="button"*/}
          {/*              className={[*/}
          {/*                homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
          {/*              ]}*/}
          {/*      >*/}
          {/*        Event Tickets*/}
          {/*      </Button>*/}
          {/*    </Link>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          <Row className={homepageStyle.zoomTitle} id="zoomTitle" style={{height: "2rem"}}></Row>

          {/*<Row className={homepageStyle.zoomTitle}>*/}
          {/*  <Col>*/}
          {/*    <h4>Monthly Mom Coaching Coffee Chat!</h4>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          {/*<Row className={homepageStyle.zoomSubtitle} id="zoomSubtitle">*/}
          {/*  <Col>*/}
          {/*    <p>Join Aimee Cruz, Founder of Moms on Maternity, and Sasha Morozov, Founder of Sasha X Home for a powerful*/}
          {/*      monthly wisdom and coffee chat. These two women are experts in working motherhood, have been in your shoes,*/}
          {/*      and love to have honest conversations. There will be a 15-minute teaching and the rest of the time is*/}
          {/*      spent on group exercise and group discussion. Grab your cup of coffee and mark your calendar for the Second*/}
          {/*      Monday of every month at 11am Pacific. We can’t wait to get to know you virtually and connect. There is*/}
          {/*      no cost, it’s about community all the way.</p>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          {/*<Row className={homepageStyle.coachingCoffeeChatSubtitle} >*/}

          {/*  <Col md={5} sm={12} xs={12} className={"offset-md-1"}>*/}
          {/*    <Form onSubmit={handleCoffeeChatFormChange} >*/}

          {/*      <Form.Row className={[homepageStyle.rowStyle, "justify-content-md-left"].join(" ")}>*/}
          {/*        <Col>*/}
          {/*          <Form.Group as={Col} controlId="coffeeChatName" name="coffeeChatName"*/}
          {/*                      className={[homepageStyle.registerFormGroup, homepageStyle.formLabel].join(' ')}>*/}
          {/*            <Form.Label className={[formStyle.formLabel, formStyle.required]}>Your Name</Form.Label>*/}
          {/*            <Form.Control*/}
          {/*              className={homepageStyle.formInput}*/}
          {/*              size="sm"*/}
          {/*              type="text"*/}
          {/*              name="coffeeChatName"*/}
          {/*              placeholder="Full name here"*/}
          {/*              value={coffeeChatFormState.coffeeChatName}*/}
          {/*              onChange={(e) => {handleCoffeeChatFormChange(e); setShowCoffeeChatNameErrorMessage({ show: false })}}*/}
          {/*            />*/}
          {/*          </Form.Group>*/}
          {/*        </Col>*/}
          {/*      </Form.Row>*/}

          {/*      <Form.Row>*/}
          {/*        <Col xs={12} md={12} sm={12}>*/}
          {/*          {showCoffeeChatNameErrorMessage.show && (*/}
          {/*            <Alert*/}
          {/*              variant="danger"*/}
          {/*              onClose={() => setShowCoffeeChatNameErrorMessage({ show: false })}*/}
          {/*              dismissible*/}
          {/*            >*/}
          {/*              {showCoffeeChatNameErrorMessage.message}*/}
          {/*            </Alert>*/}
          {/*          )}*/}
          {/*        </Col>*/}
          {/*      </Form.Row>*/}

          {/*      <Form.Row className={[homepageStyle.rowStyle, "justify-content-md-left"].join(" ")}>*/}
          {/*        <Col xs={12} sm={12} md={12}>*/}
          {/*          <Form.Group as={Col} controlId="coffeeChatEmail" name="coffeeChatEmail"*/}
          {/*                      className={[homepageStyle.registerFormGroup, homepageStyle.formLabel].join(' ')}>*/}
          {/*            <Form.Label className={[formStyle.formLabel, formStyle.required]}>Email address</Form.Label>*/}
          {/*            <Form.Control*/}
          {/*              className={homepageStyle.formInput}*/}
          {/*              size="sm"*/}
          {/*              value={coffeeChatFormState.coffeeChatEmail}*/}
          {/*              type="email"*/}
          {/*              name="coffeeChatEmail"*/}
          {/*              placeholder="Type your email"*/}
          {/*              onChange={(e) => {handleCoffeeChatFormChange(e); setShowCoffeeChatEmailErrorMessage({ show: false })}}*/}
          {/*            />*/}
          {/*          </Form.Group>*/}
          {/*        </Col>*/}
          {/*      </Form.Row>*/}

          {/*      <Form.Row>*/}
          {/*        <Col xs={12} md={12} sm={12}>*/}
          {/*          {showCoffeeChatEmailErrorMessage.show && (*/}
          {/*            <Alert*/}
          {/*              variant="danger"*/}
          {/*              onClose={() => setShowCoffeeChatEmailErrorMessage({ show: false })}*/}
          {/*              dismissible*/}
          {/*            >*/}
          {/*              {showCoffeeChatEmailErrorMessage.message}*/}
          {/*            </Alert>*/}
          {/*          )}*/}
          {/*        </Col>*/}
          {/*      </Form.Row>*/}

          {/*      <Form.Row>*/}
          {/*        <Col>*/}
          {/*          <Form.Group as={Col} controlId="my_multiselect_field">*/}
          {/*            <Form.Label>Select dates</Form.Label>*/}
          {/*            <MultiSelect*/}
          {/*              options={options}*/}
          {/*              value={selected}*/}
          {/*              onChange={setSelected}*/}
          {/*              labelledBy={"Select"}*/}
          {/*            />*/}
          {/*          </Form.Group>*/}
          {/*        </Col>*/}
          {/*      </Form.Row>*/}

          {/*      <Form.Row>*/}
          {/*        <Col xs={12} md={12} sm={12}>*/}
          {/*          {showCoffeeChatDatesCallErrorMessage.show && (*/}
          {/*            <Alert*/}
          {/*              variant="danger"*/}
          {/*              onClose={() => setShowCoffeeChatDatesCallErrorMessage({ show: false })}*/}
          {/*              dismissible*/}
          {/*            >*/}
          {/*              {showCoffeeChatDatesCallErrorMessage.message}*/}
          {/*            </Alert>*/}
          {/*          )}*/}
          {/*        </Col>*/}
          {/*      </Form.Row>*/}

          {/*      <Button type="button"*/}
          {/*        className={[*/}
          {/*          homepageStyle.submitButton, homepageStyle.homepageButton*/}
          {/*        ]}*/}
          {/*        onClick={validateAndSaveCoffeeChatFormData}>*/}
          {/*        Submit request*/}
          {/*      </Button>*/}
          {/*    </Form>*/}

          {/*    <Row>*/}
          {/*      <Col xs={12} sm={8} className={styles.alertMessage}>*/}
          {/*        {*/}
          {/*          showAlert.show && formResult.result === 'success' &&*/}
          {/*          <Alert variant="success" className={homepageStyle.succesAlert} onClose={() => setShowAlert(false)} dismissible>{showAlert.msg}</Alert>*/}
          {/*        }*/}
          {/*        {*/}
          {/*          showError.show &&*/}
          {/*          <Alert variant="danger" onClose={() => setShowErrorAlert({show: false})} dismissible*/}
          {/*          >{showError.onField} field should not be empty</Alert>*/}
          {/*        }*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </Col>*/}
          {/*  <Col md={5} sm={12} xs={12} className={"offset-md-0"}>*/}
          {/*    <Img fluid={data.frontpageCoachingCoffeeChatPhoto.childImageSharp.fluid}/>*/}
          {/*  </Col>*/}
          {/*</Row>*/}

          {/*<Row className={homepageStyle.babyAndToddlerPhotoSection}>*/}
          {/*  <Col>*/}
          {/*    <Row className={[homepageStyle.secondTitle]}>*/}
          {/*      <Col>*/}
          {/*        <h4 className={homepageStyle.blackZoomTitle}>Baby and Toddler Virtual Photo Fun</h4>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}

          {/*    <Row className={homepageStyle.zoomSubtitle}>*/}
          {/*      <Col>*/}
          {/*        <p>We know one thing we all have in common – taking photos of our babies!*/}
          {/*          Let us transform one of yours into a fun and fine portrait!</p>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}

          {/*    <Row className={homepageStyle.babyAndToddlerButtonsSection}>*/}
          {/*      <Col md={5} sm={5} xs={12} className={"offset-md-1"}>*/}
          {/*        <Img fluid={data.oliviaFramedPhoto.childImageSharp.fluid}/>*/}
          {/*        <Link id="hoverShowImage" to="/baby-portraits?type=holiday">*/}
          {/*          <Button type="button"*/}
          {/*                  className={[*/}
          {/*                    homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
          {/*                  ]}*/}
          {/*          >*/}
          {/*            Holiday Portraits*/}
          {/*          </Button>*/}
          {/*        </Link>*/}
          {/*      </Col>*/}
          {/*      <Col md={5} sm={5} xs={12} className={"offset-md-0"}>*/}
          {/*        <Img fluid={data.lucasFramedPhoto.childImageSharp.fluid}/>*/}
          {/*        <Link id="hoverShowImage" to="/baby-portraits?type=classic">*/}
          {/*          <Button type="button"*/}
          {/*                  className={[*/}
          {/*                    homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
          {/*                  ]}>*/}
          {/*            Classic Portraits*/}
          {/*          </Button>*/}
          {/*        </Link>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}

          {/*  </Col>*/}
          {/*</Row>*/}

          {/*<Row>*/}
          {/*  <Col>*/}
              {/*<Row className={[homepageStyle.secondTitle]}>*/}
              {/*  <Col>*/}
              {/*    <h4 className={homepageStyle.blackZoomTitle}>Newest Content</h4>*/}
              {/*  </Col>*/}
              {/*</Row>*/}

              {/*<Row className="justify-content-md-center">*/}
              {/*  {data.allMarkdownRemark.edges.map(({node}) => (*/}
              {/*    <Col md={3} sm={12} xs={12} key={node.id}>*/}
              {/*      <Link id="hoverShowImage" to={node.frontmatter.path} target="_blank" rel="noreferrer">*/}
              {/*        <Img className={homepageStyle.postTypeImage} fluid={node.frontmatter.squaredImage.childImageSharp.fluid}/>*/}
              {/*      </Link>*/}
              {/*      <div className={homepageStyle.buttonContainer}>*/}
              {/*        <Link id="hoverShowImage" to={node.frontmatter.path} target="_blank" rel="noreferrer">*/}
              {/*          <Button type="button"*/}
              {/*                  className={[*/}
              {/*                    homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
              {/*                  ]}*/}
              {/*                  >*/}
              {/*            Blogs*/}
              {/*          </Button>*/}
              {/*        </Link>*/}
              {/*      </div>*/}
              {/*    </Col>*/}
              {/*  ))}*/}

              {/*  {data.vlogs.edges.map(({node}) => (*/}
              {/*    <Col md={3} sm={12} xs={12}  key={node.id}>*/}
              {/*      <Link id="hoverShowImage" to={node.frontmatter.path} target="_blank" rel="noreferrer">*/}
              {/*        <Img className={homepageStyle.postTypeImage} fluid={node.frontmatter.squaredImage.childImageSharp.fluid}/>*/}
              {/*      </Link>*/}
              {/*      <div className={homepageStyle.buttonContainer}>*/}
              {/*        <Link id="hoverShowImage" to={node.frontmatter.path} target="_blank" rel="noreferrer">*/}
              {/*          <Button type="button"*/}
              {/*                  className={[*/}
              {/*                    homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
              {/*                  ]}>*/}
              {/*            Vlogs*/}
              {/*          </Button>*/}
              {/*        </Link>*/}
              {/*      </div>*/}
              {/*    </Col>*/}
              {/*  ))}*/}

              {/*  <Col md={3} sm={12} xs={12} >*/}
              {/*    <a id="hoverShowImage"*/}
              {/*          href="https://podcasts.apple.com/us/podcast/the-moms-on-maternity-podcast-with-aimee-cruz/id1556912487"*/}
              {/*          target="_blank" rel="noreferrer">*/}
              {/*      <Img className={homepageStyle.postTypeImage} fluid={data.podcastPhoto.childImageSharp.fluid}/>*/}
              {/*    </a>*/}
              {/*    <div className={homepageStyle.buttonContainer}>*/}
              {/*      <a id="hoverShowImage"*/}
              {/*            href="https://podcasts.apple.com/us/podcast/the-moms-on-maternity-podcast-with-aimee-cruz/id1556912487"*/}
              {/*            target="_blank" rel="noreferrer">*/}
              {/*        <Button type="button"*/}
              {/*                className={[*/}
              {/*                  homepageStyle.submitButton, homepageStyle.redirectButton, homepageStyle.homepageButton*/}
              {/*                ]}>*/}
              {/*          Podcasts*/}
              {/*        </Button>*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  </Col>*/}
              {/*</Row>*/}

          {/*  </Col>*/}
          {/*</Row>*/}

          <Row className={[homepageStyle.carouselContent, "carouselContent"].join(' ')}>
            <Col className="col-md-offset-1 col-md-offset-0">
              <h4 className={homepageStyle.blackZoomTitle}>Our Team</h4>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                autoPlay={true}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 4,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {data.momTeam.siteMetadata.momTeam.map(member => (
                  <div key={member.name}>
                    <TeamCarousel
                      photo={resourceImages[member.photo]['childImageSharp']['fluid']}
                      name={member.name}
                      position={member.position}>
                    </TeamCarousel>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>

        </Col>
      </Row>

    </Layout>
  )
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: {posttype: {eq: "blogpost"}}},
      sort: {order: DESC, fields: frontmatter___date},
      limit: 1
    ) {
      totalCount
      edges {
        node {
          id
          rawMarkdownBody
          frontmatter {
            path
            title
            date(formatString: "DD MMMM, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            squaredImage {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
     vlogs: allMarkdownRemark(
      filter: {frontmatter: {posttype: {eq: "vlog"}}},
      sort: {order: DESC, fields: frontmatter___date},
      limit: 1
    ) {
      totalCount
      edges {
        node {
          id
          rawMarkdownBody
          frontmatter {
            path
            title
            date(formatString: "DD MMMM, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            squaredImage {
            childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
        }
      }
    }
    laborBookPhoto: file(relativePath: { eq: "labor-book-promo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tummytimePhoto: file(relativePath: { eq: "tummytime-promo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mommyshelperPhoto: file(relativePath: { eq: "mommys-helper-promo.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    podcastPhoto: file(relativePath: { eq: "markdown-podcasts/podcast.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutUsPhoto: file(relativePath: { eq: "about-us-new.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    frontpageAd: file(relativePath: { eq: "frontpage/ash-jacobs-ad1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    frontpageCover: file(relativePath: { eq: "frontpage/homepage-cover-photo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    frontpageCoachingCoffeeChatPhoto: file(relativePath: { eq: "frontpage/mom-coaching-coffee-chat.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    oliviaFramedPhoto: file(relativePath: { eq: "portrait-photos/frames/framed/olivia.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lucasFramedPhoto: file(relativePath: { eq: "portrait-photos/frames/framed/lucas.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    momTeam: site {
      siteMetadata {
        momTeam {
          name
          position
          photo
        }    
      }
    }
    teamImages: allFile(filter: {relativePath: {regex: "/team\/.*/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
          name
        }
      }
    }
    logo: file(relativePath: { eq: "logo-new.png" }) {
      publicURL
    }
    mindfulnessUpcomingEventPhoto: file(relativePath: { eq: "events/mindfulness-yoga-and-creativity-for-families-event.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Index
