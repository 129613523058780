import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Row, Col, Button } from "react-bootstrap"

const LocalCard = ({ styles, href, fluid, buttonText }) => {
  return (
    <Col md={3} sm={12} xs={12}>
      <Link id="hoverShowImage" to={href} target="_blank" rel="noreferrer">
        <Img className={styles.postTypeImage} fluid={fluid} />
      </Link>
      <div className={styles.buttonContainer}>
        <Link id="hoverShowImage" to={href} target="_blank" rel="noreferrer">
          <Button
            type="button"
            className={[
              styles.submitButton,
              styles.redirectButton,
              styles.homepageButton,
            ].join(" ")}
          >
            {buttonText}
          </Button>
        </Link>
      </div>
    </Col>
  )
}

const RemoteCard = ({ styles, href, fluid, buttonText }) => {
  return (
    <Col md={3} sm={12} xs={12}>
      <a id="hoverShowImage" href={href} target="_blank" rel="noreferrer">
        <Img className={styles.postTypeImage} fluid={fluid} />
      </a>
      <div className={styles.buttonContainer}>
        <a id="hoverShowImage" href={href} target="_blank" rel="noreferrer">
          <Button
            type="button"
            className={[
              styles.submitButton,
              styles.redirectButton,
              styles.homepageButton,
            ].join(" ")}
          >
            {buttonText}
          </Button>
        </a>
      </div>
    </Col>
  )
}

const HomeCard = (props) => {
  return props.href.includes("http") ? (
    <RemoteCard {...props} />
  ) : (
    <LocalCard {...props} />
  )
}
export default HomeCard
